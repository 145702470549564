import { RichEnum, RichEnumOption } from './RichEnum';
import { OrderParams } from './api/OrderParams';
import { sealed } from '../helpers/sealed.decorator';
import { M } from '@angular/cdk/keycodes';

export enum SERPSentiment {
    NotSet = -2,
    Negative,
    NA,
    Positive
}

export enum ContactReportType {
    Contacts = -1,
    SalesDashboard,
    Sales,
    Legal
}

export enum CustomerLinkWftStatus {
    Any = -1, /* primo stato */
    NotProcessed, /* primo stato */
    Ok, /* positivo */
    Error, /* negativo */
    UrlNotAvailableForWft, /* negativo */
    UrlAvailableForWft, /* primo stato */
    Loading /* primo stato */
}

export enum SoftLegalTab {
    NoTab = -1,
    TODO,
    NotWorkable,
    NotAvailable,
    WebForm,
    Instance,
    SearchEngine,
    All
}

export enum PopupTableType {
    LinkEventToAsset,
    LinkEventToLink,
    LinkContentToAsset,
    LinkAssetToContent,
    LinkDomainToTtd,
    LinkedEventsToLink,
    LinkContacts,
    MassiveContents
}

export enum TaskManagerType {
    Unknown = 0,
    Build,
    Publish,
    Review,
    OpenChannel,
    OptimizationSEO
}

export enum UpdateType {
    RouterLink,
    Popup
}

@sealed
export class BooleanEnum extends RichEnum {
    constructor() {
        super(
            'BOOLEAN',
            new RichEnumOption(1, 'NO'),
            new RichEnumOption(2, 'YES'),
        );
    }
}

@sealed
export class BooleanEnumWithUnknown extends RichEnum {
    constructor() {
        super(
            'BOOLEAN_WITH_UNKNOWN',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'NO'),
            new RichEnumOption(2, 'YES'),
        );
    }

    public fromExact(value: boolean): string {
        let num = (value === null || value === undefined) ? 0 : (value === true ? 2 : 1);
        return this.getForValue(num).toString();
    }
}


@sealed
export class SERPFrequency extends RichEnum {
    public static Disabled: number = 0;
    public static Weekly: number = 1;
    public static Monthly: number = 2;
    public static Manual: number = 3;

    constructor() {
        super(
            'SERP_FREQUENCY',
            new RichEnumOption(SERPFrequency.Disabled, 'DISABLED'),
            new RichEnumOption(SERPFrequency.Weekly, 'WEEKLY'),
            new RichEnumOption(SERPFrequency.Monthly, 'MONTHLY'),
            new RichEnumOption(SERPFrequency.Manual, 'MANUAL'),
        );
    }
}

@sealed
export class SERPBoxType extends RichEnum {
    public static Link: number = 0;
    public static Image: number = 1;
    public static Video: number = 2;
    public static News: number = 3;
    public static SubLink: number = 4;

    constructor() {
        super(
            'SERP_BOXTYPE',
            new RichEnumOption(SERPBoxType.Link, 'LINK'),
            new RichEnumOption(SERPBoxType.Image, 'IMAGE'),
            new RichEnumOption(SERPBoxType.Video, 'VIDEO'),
            new RichEnumOption(SERPBoxType.News, 'NEWS'),
            new RichEnumOption(SERPBoxType.SubLink, 'SUBLINK'),
        );
    }
}

@sealed
export class SearchOrder extends RichEnum {
    static Reversed: number = -1;
    static NoOrder: number = 0;
    static Straight: number = 1;

    static changeOrder(param: OrderParams, propName: string): void {
        const currentVal = param.order;
        if (currentVal === undefined || currentVal === null)
            return;

        if (propName != param.orderPropertyName) {
            param.orderPropertyName = propName;
            param.order = SearchOrder.Straight;
            return;
        }

        param.orderPropertyName = propName;

        if (currentVal == SearchOrder.Straight) {
            param.order = SearchOrder.Reversed;
            return;
        }

        if (currentVal == SearchOrder.Reversed || currentVal == SearchOrder.NoOrder) {
            param.order = SearchOrder.Straight;
            return;
        }
    }

    static getFlipIconClass(param: OrderParams, propertyName: string) {
        if (param.orderPropertyName !== propertyName)
            return 'd-none';

        if (param.order === SearchOrder.NoOrder)
            return 'd-none';

        if (param.order !== SearchOrder.Straight)
            return 'flip-icon';

        return null;
    }

    constructor() {
        super(
            'SEARCH_ORDER',
            new RichEnumOption(SearchOrder.Reversed, 'Reversed'),
            new RichEnumOption(SearchOrder.NoOrder, 'No order'),
            new RichEnumOption(SearchOrder.Straight, 'Straight'),
        );
    }
}

@sealed
export class CaseType extends RichEnum {
    constructor() {
        super(
            'CASE_TYPE',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(2, 'PERSONAL'),
            new RichEnumOption(4, 'MIDDLE'),
            new RichEnumOption(8, 'EXECUTIVE'),
            new RichEnumOption(1, 'CORPORATE')
        );
    }
}

@sealed
export class ContactStatus extends RichEnum {
    public static readonly NoPotential: number = 1;
    public static readonly Prospect: number = 2;
    public static readonly Lead: number = 4;
    public static readonly Customer: number = 8;

    constructor() {
        super(
            'CONTACT_STATUS',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(ContactStatus.NoPotential, 'NO_POTENTIAL'),
            new RichEnumOption(ContactStatus.Prospect, 'PROSPECT'),
            new RichEnumOption(ContactStatus.Lead, 'LEAD'),
            new RichEnumOption(ContactStatus.Customer, 'CUSTOMER')
        );
    }
}

@sealed
export class ContactRelationship extends RichEnum {
    public static readonly Relationship: number = 0;
    public static readonly Extension: number = 1;

    constructor() {
        super(
            'CONTACT_RELATIONSHIP',
            new RichEnumOption(ContactRelationship.Relationship, 'RELATIONSHIP')
            //new RichEnumOption(ContactRelationship.Extension, 'EXTENSION')
        );
    }
}

@sealed
export class ContentType extends RichEnum {
    constructor() {
        super(
            'CONTENT_TYPE',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'AUDIO'),
            new RichEnumOption(2, 'VIDEO'),
            new RichEnumOption(3, 'TEXT'),
            new RichEnumOption(4, 'PHOTO'),
        );
    }
}

@sealed
export class ContentStatus extends RichEnum {
    constructor() {
        super(
            'CONTENT_STATUS',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'DRAW_UP'),
            new RichEnumOption(2, 'UNDER_VALIDATION'),
            new RichEnumOption(3, 'VALIDATED'),
            new RichEnumOption(4, 'PUBLISHED')
        );
    }
}

@sealed
export class InstanceLinkStatus extends RichEnum {
    static readonly Unknown: number = 0;
    static readonly Anonymized: number = 1;
    static readonly Deleted: number = 2;
    static readonly UnIndexed: number = 4;
    static readonly Updating: number = 8;
    static readonly Updated: number = 16;
    static readonly Refused: number = 32;
    static readonly Unworkable: number = 64;
    static readonly Blocked: number = 128;

    constructor() {
        super(
            'INSTANCE_LINK_STATUS',
            new RichEnumOption(InstanceLinkStatus.Unknown, 'N/A'),
            new RichEnumOption(InstanceLinkStatus.Anonymized, 'ANONYMIZED'),
            new RichEnumOption(InstanceLinkStatus.Deleted, 'CANCELLED'),
            new RichEnumOption(InstanceLinkStatus.UnIndexed, 'DE_INDEXED'),
            new RichEnumOption(InstanceLinkStatus.Updating, 'UPDATING'),
            new RichEnumOption(InstanceLinkStatus.Updated, 'UPDATED'),
            new RichEnumOption(InstanceLinkStatus.Refused, 'REJECTED'),
            new RichEnumOption(InstanceLinkStatus.Unworkable, 'UNWORKABLE'),
            new RichEnumOption(InstanceLinkStatus.Blocked, 'BLOCKED')
        );
    }
}



@sealed
export class Enums {
    public readonly BooleanEnum = new BooleanEnum();
    public readonly BooleanEnumWithUnknown = new BooleanEnumWithUnknown();
    public readonly ContactRelationship = new ContactRelationship();
    // CONTENT ENUMS
    public readonly CaseType = new CaseType();
    public readonly ContentType = new ContentType();
    public readonly ContentStatus = new ContentStatus();
    // CUSTOMER LINK ENUMS
    public readonly InstanceLinkStatus = new InstanceLinkStatus();
    // SERP
    public readonly SERPFrequency = new SERPFrequency();
    public readonly SERPBoxType = new SERPBoxType();

    public filterEnumValues(option: RichEnumOption): boolean {
        return option.name && option.value !== undefined && option.value !== 0;
    }
}
