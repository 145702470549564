import { TableOptions, TableActions, GetCustomerLinkParams, UpdateType, SoftLegalTab } from '../models';

export class SoftLegalTableOptionsAll extends TableOptions {
    constructor() {
        super('SoftLegal', true);
        this.buttons = [
            TableActions.DeleteMany,
            TableActions.GenericEdit
        ];
        this.params = new GetCustomerLinkParams();
        this.newParams = new GetCustomerLinkParams();
        //unset pagination
        this.params.page = 0;
        this.newParams.page = 0;
        //custom Path fetch
        this.paths.basePath = 'SoftLegal';
        this.paths.fetchPath = "/api/SoftLegal/MyForCustomer";
        this.paths.updatePath = () => '/';

        this.displayedColumns = [
            'url',
            'softLegal.status',
        ];
        this.formats = {
            'url': {
                label: 'URL',
                sort: true,
                type: 'dangerousLink',
                plainText: false
            },            
            'softLegal.status': {
                label: 'STATUS',
                sort: false,
                plainText: false,
                type: 'enum',
                format: () => this.enums.InstanceLinkStatus,
                translate: true,
                colorEnum: {4: 'green', 1: 'green', 2: 'green', 16: 'green', 32: 'red'}
            },
           
        };
        this.update = {
            action: (row: any) => {
                return { url: row.url };
            },
            enabled: false,
            type: UpdateType.RouterLink,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'URL',
                formName: 'sl_url_filter',
                type: 'text-input',
                class: 'col-12 col-lg',
                param: 'linkPart'
            },
            {
                name: 'STATUS',
                formName: 'as_type_filter',
                type: 'select',
                class: 'col-12 col-lg',
                param: 'result',
                enum: 'InstanceLinkStatus'
            },
           
        ];

        this.pagination.enabled = false;
        this.pagination.showButtons = false;
    }

}