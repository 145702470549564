import { BaseParamsWithContactId } from './BaseParamsWithContactId';
import { RichEnum } from '../RichEnum';

export class ContentParams extends BaseParamsWithContactId {
    constructor(
        public isOnLine: number = null,
        public isUsed: number = null,
        public rmPublished: boolean = null,
        public managedByReputationManager: boolean = null,
        public typology: number = null,
        public status: number = null,
        public possibleUse: number = null,
        public pertinence: number = 1,
        public evaluation: number = null,
        public managedBy: number = null,
        public url: string = null,
        public keyword: string = null,
        public sender: number = null,
        public publicationDate: string = null,
        public fileName: string = null,
        public withoutValues: boolean = null,
        public publicationDateFrom: Date = null,
        public publicationDateTo: Date = null,
        public insertDateFrom: Date = null,
        public insertDateTo: Date = null,
        public commonNotes: string = null,
        public preview: string = null,
        public title: string = null,
        public subCategoryIds: number[] = []
    ) {
        super();
    }
}

export class LinksToAssets {
    constructor(
        public CustomerId: number = 0,
        public ImportToSoftLegal: boolean = false,
        public TakeFromUnium: boolean = false,
        public InheritedContentIds = [],
    ) {}
}


export class LinksToAssetsAlertOptions {
    constructor(
        public ImportToSoftLegal: boolean = false,
        public TakeFromUnium: boolean = false
    ) { }
}