// Core
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/index";
import { MatDialog } from "@angular/material/dialog";

// Instruments
import {
    AlertData,
    PromptAlertData,
    LinkTrendData,
    serpGeneratorData, serpPositionData
} from './AlertData';
import {
    PopupTableType,
    Domain,
    ContentModel,
    SERPLinkPositionTrend,
} from '../../instruments/models';

// Components
import { AlertComponent } from './alert.component';

@Injectable()
export class AlertService {

    constructor ( private dialog: MatDialog) {

    }

    open(alertData: AlertData, wide: boolean = false): Observable<string> {
        const dialogRef = this.dialog.open(AlertComponent, {
            data: alertData,
            width: wide ? '1300px' : '550px',
        });
        return dialogRef.afterClosed();
    }

    prompt(promptTitle: string = 'MESSAGES.ARE_YOU_SURE'): Observable<string> {
        return this.open(new PromptAlertData(promptTitle));
    }

    linkPositionTrend(data: SERPLinkPositionTrend, title: string = 'SERP.LINK_POSITION_TREND', url: string): Observable<string> {
        return this.open(new LinkTrendData(data, title, url), true);
    }

    serpGenerator(data = null, title: string = 'SERP.LINK_POSITION_TREND', url: string): Observable<string> {
        return this.open(new serpGeneratorData(data, title, url), true);
    }

    serpPosition(data = null, title: string = 'SERP.POSITION', url: string = ''): Observable<string> {
        return this.open(new serpPositionData(data, title, url), true);
    }
}
