import { ContentCheckModel } from '../';

export class SoftLegal {
    contactId: number = 0;
    contentId: number= 0;
    instanceId: number;
    workability: number = 0;
    contract: boolean = false;
    TTDName: string;
    eventsNames: [string];
    instanceReceiverOrTTD: string;
    instanceReceiver: string;
    eventName: string;
    status: number;
    lastStatusUpdate: Date;
    insertDate: Date;
    lastCheck: Date;
    lastUpdate: Date;
    autority: boolean;
    isSoftLegal: boolean;
    webForm: boolean;
    successFee: number;
    blockedDomainsRequired: [number];
    autorityActivationDate: Date;
    webFormActivationDate: Date;
    googleActivationDate: Date;
    yahooActivationDate: Date;
    bingActivationDate: Date;
    instanceResponse: boolean;
    autorityResponse: boolean;
    webFormResponse: boolean;
    googleResponse: boolean;
    yahooResponse: boolean;
    bingResponse: boolean;

    // FOR REPORT ONLY
    instanceName: string;
    instanceStatus: number;

    // CHECK PROPERTIES, FILL ON DEMAND
    statusNotMatch: boolean;
    lastAutoCheck: ContentCheckModel;
    isLinkWithDocuments: boolean;
    isWithInstanceSentOrClose: boolean;
    instanceActivationDate: Date;
}