// Core
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Component, Inject, OnInit} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SerpGeneratorComponent } from '../../pages/serp-generator-page/serp-generator/serp-generator.component';
import { LinkPositionTrendComponent } from '../link-position-trend/link-position-trend.component';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {SerpPositionComponent} from '../serp-position/serp-position.component';

@Component({
    selector: 'alert-component',
    templateUrl: './alert.component.html',
    styleUrls: ['alert.component.css'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        NgIf,
        LinkPositionTrendComponent,
        SerpGeneratorComponent,
        TranslateModule,
        SerpPositionComponent,
    ],
})
export class AlertComponent implements OnInit {
    uploadHeaders: HttpHeaders;
    resetSoft: boolean = true;

    constructor(        
        public dialogRef: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.appendCustomerId();
    }

    public _cancel() {
        this.dialogRef.close('cancel');
    }

    public _ok() {
        this.resetSoft = false;
        this.dialogRef.close('ok');
    }

    private appendCustomerId(): void {
        if (this.data.link != undefined) {
            if (this.data.link.contentId != undefined) {
                let headers = new HttpHeaders();
                headers = headers.append('ContactId', this.data.link.contactId.toString());
                headers = headers.append('ContentId', this.data.link.contentId.toString());
                this.uploadHeaders = headers;
            }
        }
    }
}
