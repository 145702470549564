import { Enums, Domain, ContentCustomerExtData, CustomerChannel, ContentBuild, SoftLegal, SerpContentInfo, SERPSentiment } from '../';
import { MatrixMacroCategory } from "../valueMatrix/CustomerValueMatrix";
import { SafeResourceUrl } from '@angular/platform-browser';

export class ContentModel {
    contentId: number;
    contactId: number;
    type: number;
    url: string;
    title: string;
    preview: string;
    fileName: string;
    gridfsFileId: string;
    hash256: string;
    file: any;
    publicationDate: Date;
    previewUrl: string;
    embedUrl: SafeResourceUrl;
    content_Subtype: string;
    insertDate: Date;
    managedBy: any[];
    author: string;
    fullText: string;
    domainId: number;
    serpLastExecutions: any[];
    linkToCustomerChannelContentId: number;
    domain: Domain;
    contentCustomerExtData: ContentCustomerExtData;
    customerChannel: CustomerChannel;
    contentBuild: ContentBuild;
    softLegal: SoftLegal;
    serpContentInfo: SerpContentInfo;
    macroCategories: MatrixMacroCategory[];
    contentWidget: ContentWidget;
    sentiment: SERPSentiment;

    constructor(contactId: number, softLegal: SoftLegal, serpContentInfo: SerpContentInfo = null, domain: Domain = null, customerChannel: CustomerChannel = null) {
        this.contactId = contactId;
        this.contentCustomerExtData = {} as ContentCustomerExtData;
        this.customerChannel = null;
        this.contentBuild = null;
        this.softLegal = softLegal;
        this.serpContentInfo = serpContentInfo;
        this.domain = domain;
        this.customerChannel = customerChannel;
    }
}

export interface ContentWidget {
    serpLastExecutions: SerpLastExecution[]
}

export interface SerpLastExecution {
    position?: number
    previousPosition?: number
    description: string
    querySearchKeywords: string
    querySearchEngineHost: string
    frequency: number
    lastExecutionDate: string
    variation?: string
}
