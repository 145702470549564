// Core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SerpQueryParams } from '../../../instruments/models';
import { SERPService, SharedService } from '../../../instruments/services';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-serp-generator',
    styleUrls: ['./serp-generator.component.css'],
    templateUrl: './serp-generator.component.html',
    standalone: true,
    imports: [
        MatButtonModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
        MatSelectModule,
        AsyncPipe,
    ],
})

export class SerpGeneratorComponent implements OnInit {

    serpurldomain = [];
    languageQueryList = [];
    browserList = [];
    glList = [];
    cityQueryList = [];
    cityQueryListIT = [];
    cityQueryListAll = [];
    numList = [];
    pageList = [];


    myControlCityIT = new UntypedFormControl();
    myControlCityAll = new UntypedFormControl();
    myControlLang = new UntypedFormControl();
    myControlDomain = new UntypedFormControl();
    myControlGl = new UntypedFormControl();
    myControlBrowser = new UntypedFormControl();
    myControlKeyword = new UntypedFormControl();

    filteredCitiesIT: Observable<any>;
    filteredCitiesAll: Observable<any>;
    filteredLang: Observable<any>;
    filteredDomain: Observable<any>;

    cityQuery = null;
    langQuery = null;
    domainQuery = null;
    BrowserQuery = null;
    GLQuery = null;
    keyword = null;
    serpQuery = null;
    NumQuery = null;
    PageQuery = null;

    showError = false;
    showIT = true;
    copytext= false;

    constructor(
        public shared: SharedService,
        public serpService: SERPService
    ) {
    }

    ngOnInit() {

        this.serpService.getParametersItems(1).then(resp => {
            if (resp.ok) {
                this.serpurldomain = resp.data.serpParametersItems.filter(d => d.parameterName == "serpurldomain")[0].parameterItems;
                this.languageQueryList = resp.data.serpParametersItems.filter(d => d.parameterName == "lr")[0].parameterItems;
                this.browserList = resp.data.serpParametersItems.filter(d => d.parameterName == "hl")[0].parameterItems;
                this.glList = resp.data.serpParametersItems.filter(d => d.parameterName == "gl")[0].parameterItems;
                this.cityQueryList = resp.data.serpParametersItems.filter(d => d.parameterName == "uule")[0].parameterItems;
                this.cityQueryListIT = this.cityQueryList.filter(d => d.attribute == "IT");
                this.cityQueryListAll = this.cityQueryList.filter(d => d.attribute != "IT");
                this.numList = resp.data.serpParametersItems.filter(d => d.parameterName == "num")[0].parameterItems;
                this.pageList = resp.data.serpParametersItems.filter(d => d.parameterName == "start")[0].parameterItems;

                this.filteredCitiesIT = this.myControlCityIT.valueChanges.pipe(
                    startWith(''),
                    map((value: string) => this._filterCityIT(value)),
                );

                this.filteredCitiesAll = this.myControlCityAll.valueChanges.pipe(
                    startWith(''),
                    map((value: string) => this._filterCityAll(value)),
                );

                this.filteredLang = this.myControlLang.valueChanges.pipe(
                    startWith(''),
                    map((value: string) => this._filterLang(value)),
                );

                this.filteredDomain = this.myControlDomain.valueChanges.pipe(
                    startWith(''),
                    map((value: string) => this._filterDomain(value)),
                );

            }
        })

    }

    private _filterCityIT(value: string): any {
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();

            return this.cityQueryListIT.filter(option => option.text.toLowerCase().includes(filterValue));
        }

        return this.cityQueryListIT;
    }

    private _filterCityAll(value: string): any {
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();

            return this.cityQueryListAll.filter(option => option.text.toLowerCase().includes(filterValue));
        }

        return this.cityQueryListAll;
    }

    private _filterLang(value: string): any {
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();

            return this.languageQueryList.filter(option => option.text.toLowerCase().includes(filterValue));
        }

        return this.languageQueryList;
    }

    private _filterDomain(value: string): any {
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();

            return this.serpurldomain.filter(option => option.value.toLowerCase().includes(filterValue));
        }

        return this.serpurldomain;
    }

    public getCity(city) {
        this.cityQuery = city;
    }

    public getLang(lang) {
        this.langQuery = lang;
    }

    public getDomain(dom) {
        this.domainQuery = dom;
    }

    public displayOption(obj) {
        if (obj)
            return obj.text;
        return null;
    }

    public displayOptionValue(obj) {
        if (obj)
            return obj.value;
        return null;
    }

    public changeCity(value) {
        if (value == "it")
            this.showIT = true;
        else
            this.showIT = false;
    }


    //serpurldomain, q, hl -> Browser , gl -> Nazione, lr, pws, uule, gws_rd
    //{ name: 'lr', value: this.langQuery ? this.langQuery.value : null },
    public getQuery() {

        if (this.domainQuery && this.keyword && this.cityQuery && this.BrowserQuery && this.GLQuery) {

            let params: SerpQueryParams = {
                Parameters: [
                    { name: 'serpurldomain', value: this.domainQuery.value },
                    { name: 'q', value: this.keyword },
                    { name: 'uule', value: this.cityQuery.value },
                    { name: 'hl', value: this.BrowserQuery.value },
                    { name: 'gl', value: this.GLQuery.value },
                ]
            };

            if(this.NumQuery){
                params.Parameters.push({ name: 'num', value: this.NumQuery.value })
            }
            if(this.PageQuery){
                params.Parameters.push({ name: 'start', value: this.PageQuery?.value})
            }

            this.serpService.getQueryByParam(params).then(response => {
                if (response.ok) {
                    this.serpQuery = response.data;
                }
            });

            this.showError = false;
        } else {
            this.showError = true;
        }
    }

    public copyToBoard(text) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.copytext = true;

        setTimeout(() => {
            this.copytext = false;
        }, 1500);
    }


    public reset() {
        this.myControlDomain.reset();
        this.myControlBrowser.reset();
        this.myControlGl.reset();
        this.myControlKeyword.reset();
        this.myControlCityAll.reset();
        this.myControlCityIT.reset();

        this.domainQuery = null;
        this.keyword = null;
        this.cityQuery = null;
        this.BrowserQuery = null;
        this.GLQuery = null;
        this.serpQuery = null;
        this.NumQuery = null;
        this.PageQuery = null;

        this.showError = false;
    }
}