import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, LinkedChannelParams, Channel, IUploadedDocument, BaseParamsWithContactId } from "../models";
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
@RoutePrefix('/api/ContactDocument')
export class ContactDocumentService {

    constructor(private httpClient: HttpClient) {
    
    }

    @Route('MyUniumXDocuments')
    public getMyUniumxDocuments(params: BaseParamsWithContactId, url: string = ''): Promise<JsonReply<IUploadedDocument[]>> {
        return this.httpClient.post<JsonReply<IUploadedDocument[]>>(url, params).toPromise();
    }

    @Route('UploadMyUniumXDocument')
    public myuniumxupload(file: any, contactId: number, url: string = ''): Promise<HttpEvent<JsonReply<any>>> {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('ContactId', contactId.toString());

        return this.httpClient.post<JsonReply<any>>(url, file, { 'headers': headers, 'observe': 'events', 'reportProgress': true, responseType: 'json' }).toPromise();
    }

    @Route('DeleteById')
    public deleteMyUniumxDocuments(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }

    @Route('Download')
    public download(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.get<JsonReply<any>>(url + "/" + id)
            .toPromise();
    }

    @Route('Download')
    public openBlob(id: number, url: string = ''): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');

        return this.httpClient.get(url + "/" + id, { responseType: 'blob' }).toPromise();
    }
}