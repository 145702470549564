import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Config } from './app.constants';

export const routes: Routes = [
    { path: Config.RoutingUrls.LOGIN, loadComponent: () => import('./pages/login/login.component') },
    { path: Config.RoutingUrls.FIND_CONTACTS, loadComponent: () => import('./pages/find-contacts/find-contacts.component') },
    { path: Config.RoutingUrls.CUSTOMER_DETAILS, loadComponent: () => import('./pages/project-details/project-details.component') },
    { path: Config.RoutingUrls.SERPGENERATOR, loadComponent: () => import('./pages/serp-generator-page/serp-generator-page.component') },
    { path: Config.RoutingUrls.REPORTPAGE, loadComponent: () => import('./pages/report-page/report-page.component') },
    { path: '', redirectTo: '/' + Config.RoutingUrls.LOGIN, pathMatch: 'full' },
    { path: '**', redirectTo: '/' + Config.RoutingUrls.LOGIN, pathMatch: 'full' }
];
