import {
    Injectable,
    EventEmitter
} from '@angular/core';
import { Config } from '../../app.constants';
import { hubConnection } from 'signalr-no-jquery';

class GetClockTimeClass {
    public Time: string;
    constructor(time: string) {
        this.Time = time;
    }
}  

@Injectable()
export class SignalRService {
    // Declare the variables  
    config: any = Config;
    private proxy: any;
    private proxyName: string = 'messageHub';
    private connection: any;
    // create the Event Emitter  
    public messageReceived: EventEmitter<GetClockTimeClass>;
    public connectionEstablished: EventEmitter<Boolean>;
    public connectionExists: Boolean;
    constructor() {
        // Constructor initialization  
        this.connectionEstablished = new EventEmitter<Boolean>();
        this.messageReceived = new EventEmitter<GetClockTimeClass>();
        this.connectionExists = false;
        // create hub connection  
        this.connection = hubConnection('http://uniumx.local:81/signalr', { useDefaultPath: false });
        // create new proxy as name already given in top  
        this.proxy = this.connection.createHubProxy('messageHub');
        // register on server events  
        this.registerOnServerEvents();
        // call the connecion start method to start the connection to send and receive events.  
        this.startConnection();
    }

    // method to hit from client  
    public sendTime() {
        // server side hub method using proxy.invoke with method name pass as param  
        this.proxy.invoke('GetRealTime');
    }

    // check in the browser console for either signalr connected or not  
    private startConnection(): void {
        debugger;
        this.connection.start().done((data: any) => {
            debugger;
            this.proxy.invoke('Connect','Andrea', data.id);
            console.log('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
            this.connectionEstablished.emit(true);
            this.connectionExists = true;
        }).fail((error: any) => {
            debugger;
            console.log('Could not connect ' + error);
            this.connectionEstablished.emit(false);
        });
    }
    private registerOnServerEvents(): void {
        //this.proxy.on('setRealTime', (data: GetClockTime) => {
        //    console.log('received in SignalRService: ' + JSON.stringify(data));
        //    this.messageReceived.emit(data);
        //});
        this.proxy.on('send', (datas: string) => {
            var clock = new GetClockTimeClass(datas);
            this.messageReceived.emit(clock);
        });
    }
}  