import {Injectable, Injector} from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse
} from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { JsonReply } from '../../models';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpCancelService {
    private cancelPendingRequests$ = new Subject<void>()

    constructor() { }

    /** Cancels all pending Http requests. */
    public cancelPendingRequests() {
        this.cancelPendingRequests$.next()
    }

    public onCancelPendingRequests() {
        return this.cancelPendingRequests$.asObservable()
    }

}

@Injectable({ providedIn: 'root' })//
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private router: Router, private injector: Injector, private httpCancelService: HttpCancelService) {

    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // AUTH
        //let token = authService.getToken();
        //if (!token) {
        //    this.router.navigate(['/login']);
        //    return Observable.create();
        //}

        //request.headers.set('Authorization', 'Bearer ' + token);


        return next.handle(request)
            .pipe(
                takeUntil(this.httpCancelService.onCancelPendingRequests()),
                tap(
                    event => {
                        if (event instanceof HttpResponse) {
                            if (JsonReply.IsJsonReply(event.body)) {
                                let reply = event.body as JsonReply<any>;
                                // server error happened
                                //snackbar.error(reply.errorMessage);
                                if (!reply.ok) {
                                    console.error(reply.errorMessage);

                                    if (reply.errorMessage == 'Customer not valid') {
                                        this.router.navigate([environment.apiURL + '/api/Account/CustomerLogout']);
                                    }

                                    if (reply.exception) {
                                        console.error('ERROR: ', reply.exception);
                                    }
                                }
                            }
                        }
                }, error => {
                    // http error happened
                    // display error on client
                    if (error.status == 401) {
                        this.router.navigate(['/login']);
                        return;
                    } else {
                        
                    }

                    // log to console
                    console.log("---- response ----");
                    console.error("status code: ", error.status);
                    console.error("ERROR: ", error.message);
                    console.log("---- end of response ----");
                }
            )
        );
    }
}


