import { CdkTableModule } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { SwiperModule } from 'swiper/angular';
import { APP_CONFIG, Config } from './app.constants';
import { routes } from './app.routers';
import { ApiRequestInterceptor } from './instruments';
import { MaterialModule } from './instruments/material.module';
import { ContactDocumentService } from './instruments/services';
import { ServicesModule } from './instruments/services/services.module';
import { SignalRService } from './instruments/services/signalr.service';
import { NgxPiwikProModule } from '@piwikpro/ngx-piwik-pro'; 
import { environment } from '../environments/environment';


// AoT requires an exported function for factories
function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './app_assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withViewTransitions()),
        importProvidersFrom(SwiperModule, MatBottomSheetModule, BrowserModule, FormsModule, ReactiveFormsModule, MaterialModule, ServicesModule, CdkTableModule, AngularEditorModule,
            TranslateModule.forRoot({
                loader: {
                    deps: [HttpClient],
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                }
            }),
            environment.production ? 
                NgxPiwikProModule.forRoot('67387a3f-f224-42d0-8e5f-97380fe834df', 'https://myuniumx.piwik.pro') :
                [],
        ),
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5500, horizontalPosition: 'right' } },
        { provide: APP_CONFIG, useValue: Config },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiRequestInterceptor,
            multi: true,
        },
        CookieService,
        DatePipe,
        SignalRService,
        ContactDocumentService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
}