import { BaseParams } from "./BaseParams";
import { CustomerServiceModel } from "../customer/CustomerServiceModel";
import { RichEnum } from "../RichEnum";

export class FindContactsParams extends BaseParams {
    name: string;
    surname: string;
    nameOrSurnameOrCompany: string;
    nameOrSurnameOrCompanyNotHead: string;
    caseType: number[];
    firstContactDateFrom: Date;
    firstContactDateTo: Date;
    firstContactDateSpecified: boolean;
    workflowResult: number[];
    requiredServices: CustomerServiceModel[];
    assignedSalesId: string;
    firstContactPersonId: string;
    contactStatus: number[];
    financialCapacity: number[];
    preAnalysisStatus: number[];
    email: string;
    origin: number;
    relation: number[];
    hasCurrentUserInTeam: boolean;

    constructor(values: Object = null) {
        super();

        this.pageSizeOptions = [30, 50, 100];
        this.pageSize = 50;

        this.name = '';
        this.surname = '';
        this.nameOrSurnameOrCompany = '';
        this.nameOrSurnameOrCompanyNotHead = '';
        this.caseType = []; //15;
        this.contactStatus = []; //RichEnum.Any;
        this.firstContactDateTo = null;
        this.firstContactDateFrom = null;
        this.firstContactDateSpecified = false;
        this.assignedSalesId = null;
        this.firstContactPersonId = null;
        this.financialCapacity = []; //RichEnum.Any;
        this.preAnalysisStatus = []; //RichEnum.Any;
        this.workflowResult = [];
        this.requiredServices = [];
        this.email = null;
        this.origin = RichEnum.Any;
        this.relation = []; //RichEnum.Any;
        this.hasCurrentUserInTeam = false;
        this.ordering.orderPropertyName = 'name';
        this.ordering.order = 1;

        if (values) {
            Object.assign(this, values);
        }
    }

    public getSaveToken(): string {
        this._save_token_ = 'FindContacts';
        return this._save_token_;
    }
}
