import {ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {ServerError} from "./ServerError";

@Injectable({ providedIn: 'root' })
export class ErrorsHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any) {

        if ((error instanceof HttpErrorResponse)|| (error.rejection && error.rejection instanceof HttpErrorResponse)) {
            // No Internet connection
            if (!navigator.onLine) {
                console.error('NO INTERNET CONNECTION');
            }

            // Http Error
            // return notificationService.error(error.message);
        } else {
            // Client Error Happend
            // router.navigate(['/error'], { queryParams: {error: error} });
        }
        // Log the error anyway
        console.error(error);
    }

}
