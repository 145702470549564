import { RichEnum, RichEnumOption } from "../RichEnum";

export class MatrixSubCategoryValue {
    constructor (public entityId: number) {}
    macroCategoryId: number;
    subCategoryId: number;
    subCategoryName: string;
    value: number;
    enabled: boolean;
    isCustomCategory: boolean;
    id: number;
    macroCategoryName: string;
    position: number;
    status: boolean;
    type: number = 0;
    weight: number;
    contactId: number;
    isCustom: boolean;
}

export class MatrixAddCustomCat {
    constructor(public entityId: number, public MatrixCategoriesValues: MatrixSubCategoryValue[]) { }
}

export class MatrixDeleteCustomCat {
    constructor(public entityId: number, public MatrixCategoriesValues: MatrixSubCategoryValue[]) { }
}

export class MatrixMacroCategory {
    macroCategoryId: number;
    contactId: number;
    contentId: number;
    type: number = 0;
    position: number;
    id: number;
    macroCategoryName: string;
    weight: number;
    value: number;
    status: number;
    subCategoryName: string;
    subCategories: MatrixSubCategoryValue[];
    checked: boolean;
}

export class SubCategory {
    ContactId: number;
    CategoryId : number;
    Value : number;
}

export class MatrixMacroUpdate {
    weight: number;
    macroCategoryId : number;
}

export class SubCategoryStatus {
    id : number;
    status : boolean;
}

export class MatrixValues {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public macroCategories: MatrixMacroCategory[]) { }
}

export class MatrixMacroValues {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public MatrixCategoriesValues: MatrixMacroUpdate[]) { }
}

export class MatrixSubValues {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public contactId: number, public MatrixCategoriesValues: SubCategory[]) { }
}

export class MatrixSubValuesMassive {
    constructor(public contentIds: number[], public customerId: number, public categoryIds: number[]) { }
}

export class MatrixSubStatus {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public MatrixCategoriesValues: SubCategoryStatus[]) { }
}

export class MacroCategoryWeight extends RichEnum {
    constructor() {
        super(
            'MACRO_CATEGORY_WEIGHT',
            new RichEnumOption(0, "NULL"),
            new RichEnumOption(1, "VERY_LOW"),
            new RichEnumOption(2, "LOW"),
            new RichEnumOption(3, "MEDIUM"),
            new RichEnumOption(4, "HIGH"),
            new RichEnumOption(5, "VERY_HIGH"),
        );
    }
}
