import {Guid} from "./Guid";

export class UsersCustomer {
    public customerId: number;
    public usersCustomer: UserModelAssociation[];

}

export class UserModelAssociation {
    public user: DomainUser;
    public isAssociated: boolean = true;
    public matrixIsActive: boolean = true;
    public timelineIsActive: boolean = true;
    public wipIsActive: boolean = true;
    public serpIsActive: boolean = true;
    public softLegalIsActive: boolean = true;
    public documentsIsActive: boolean = true;
    public serpIdsActive: number[] = [];
    public serpResults: number = 20;
}

export enum UserGroup {

    Any = -1,
    Unknown = 0,
    Users = 1,
    Sales = 2,
    Account = 4,
    Legal = 8,
    SEO = 16,
    Writers = 32,
    Admin = 64,
}

export class DomainUser {
    public id: string = null;
    public userGroup: UserGroup = UserGroup.Unknown;
    public email: string;

    constructor(public domainUserId: string, public fullName: string) {
    }

    public static equals(a: DomainUser, b: DomainUser): boolean {
        if (!a && !b)
            return true;
        if ((!a && b) || (a && !b))
            return false;

        return a.domainUserId === b.domainUserId;
    }

    public static Default: DomainUser = new DomainUser(Guid.Empty, 'N/A');
}
