import {InjectionToken} from "@angular/core";

export interface IConfig {
    // urls: RoutingUrls;
}


export class Config implements IConfig {

    static MomentDateFormat = 'DD/MM/YYYY';
    static MomentShortDateFormat = 'MMMM YYYY';
    static NativeDateFormat = 'dd/MM/yyyy';
    static NativeDateTimeFormat = 'dd/MM/yyyy HH:mm';
    static MomentDateTimeFormat = 'DD/MM/YYYY HH:mm';

    //SignalR
    public static SERVERSIGNALR = 'UniumX.SignalR';

    static RoutingUrls = class {
        public static LOGIN = 'login';
        public static FIND_CONTACTS = 'findContacts';
        public static CUSTOMER_DETAILS = 'project';
        public static SERPGENERATOR = 'serp_generator'
        public static REPORTPAGE = 'report'
    };

    static ApiUrls = class {
        public static DOWNLOAD_CONTACT_DOCUMENT = '/api/ContactDocument/Download/';
        public static DOWNLOAD_LINK_DOCUMENT = 'api/ContentDocument/Download/';
        public static UPLOAD_ASSET = '/api/Asset/Upload';
        public static UPLOAD_CONTENT = '/api/Content/Upload';
        public static CONTACT_EVENT_DASHBOARD = '/api/Customer/EventDashboard';
        public static FETCH_DOMAINS = '/api/Domain/Find';

        // CONTACT
        public static GET_CONTACTS_DATA = '/api/Customer/Find';
        public static UPLOAD_CONTACT_PHOTO = '/api/Customer/UploadPhoto';
        public static GET_CONTACT_PHOTO = '/api/Customer/GetPhoto';
        public static DELETE_CONTACT_PHOTO = '/api/Customer/DeletePhoto';
        public static GET_RELATED_CONTACTS = '/api/Customer/FindRelated';

        // INJURIOUS LINKS
        public static IMPORT_LINKS = '/api/Content/ImportLinks';

        // DOCUMENTS
        public static UPLOAD_CONTACT_DOCUMENT = '/api/Customer/UploadDocument';
        public static UPLOAD_CONTACTS_SOFT_LEGAL = "api/ContentDocument/UploadDocument";

        // TTD
        public static FETCH_TTD = '/api/TTD/Find';

        // SERP
        public static FETCH_SERP_LINKS = 'api/SERP/Link/ForResult';
        public static EXPORT_ALL_LINKS = 'api/SERP/Link/ExportAll';
        public static UPLOAD_SERP_IMAGE = 'api/SERP/Screenshot/Upload';
        public static DOWNLOAD_SERP_IMAGE = 'api/SERP/Screenshot/Download/';

        // INSTANCE DOC/PDF
        public static CREATE_INSTANCE_LETTER = '/api/Instance/CreateInstanceLetter';
        public static UPLOAD_INSTANCE_LETTER = '/api/Instance/UploadInstanceLetter';

        // BUILD PATHS
        public static forContact (path: string) {
             return 'api/' + path + '/ForContact';
        }
        public static forCustomer (path: string) {
             return '/api/' + path + '/ForCustomer';
        }
        public static add (path: string) {
             return 'api/' + path + '/Add';
        }
        public static update (path: string) {
             return 'api/' + path + '/Update';
        }
        public static delete (path: string) {
             return 'api/' + path + '/Delete';
        }
        public static deleteMany (path: string) {
             return 'api/' + path + '/DeleteMany';
        }
        public static deleteAll(path: string) {
            return 'api/' + path + '/DeleteAll';
        }
        public static exportMany (path: string) {
             return 'api/' + path + '/ExportMany';
        }
        public static exportAll(path: string) {
            return 'api/' + path + '/ExportAll';
        }
    };
}


export const APP_CONFIG = new InjectionToken<Config>('APP_CONFIG');
