import { SERPLinkPositionTrend } from '../../instruments/models';

export class AlertData {
    constructor(public title: string,
                public showCancelBtn: boolean,
                public icon: string,
                public success: boolean,
                public type: string,
                public randomString?: string,
                public afterTitle?: string) { }
}

export class PromptAlertData extends AlertData {
    constructor(public promptTitle: string) {
        super(promptTitle, true, 'help_blue', true, 'default');
    }
}

export class LinkTrendData extends AlertData {
    constructor(public trend: SERPLinkPositionTrend, public title: string, public url: string) {
        super(title, false, null, true, 'serpLinkTrend', null, url);
    }
}

export class serpGeneratorData extends AlertData {
    constructor(public data = null, public title: string, public url: string) {
        super(title, false, null, true, 'serpGenerator', null, url);
    }
}

export class serpPositionData extends AlertData {
    constructor(public data = null, public title: string, public url: string) {
        super(title, false, null, true, 'serpPosition', null, url);
    }
}
