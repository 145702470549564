import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, JsonReplyWithParams, SERPQuery, SERPResult, BaseParams, SERPLinkPositionTrend, BaseParamsWithContactId, IUploadedDocument, ContentModel, SerpQueryParams, SERPLinksAndPreview} from "../models";
import { IDocumentService } from './customerDocument.service';
import { SharedService } from './shared.service';
import { GetSERPLinkTrendParams } from '../models/api/GetSERPLinkTrendParams';
import { SerpQueryTrendParam } from '../models/api/SerpQueryTrendParam';

@Injectable()
@RoutePrefix('/api/SERP')
export class SERPService implements IDocumentService {

    constructor(private httpClient: HttpClient, private shared: SharedService) { }

    @Route('Screenshot/ForResult')
    public getDocuments(params: BaseParamsWithContactId, url: string = ''): Promise<JsonReplyWithParams<IUploadedDocument[], BaseParamsWithContactId>> {
        return this.httpClient.post<JsonReplyWithParams<IUploadedDocument[], BaseParamsWithContactId>>(url, params).toPromise();
    }

    @Route('Screenshot/Delete')
    deleteDocument(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }

    @Route('Query/MyForCustomer')
    public getQueriesForCustomer(customerId: number, url: string = ''): Promise<JsonReply<SERPQuery[]>> {
        return this.httpClient.post<JsonReply<SERPQuery[]>>(url, customerId).toPromise();
    }

    @Route('Query/Dashboard')
    public getActiveSERPReport(url: string = ''): Promise<boolean> {
        return this.shared.downloadFile(url, null);
    }

    @Route('Query/Add')
    public addQuery(query: SERPQuery, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, query).toPromise();
    }

    @Route('Query/Update')
    public updateQuery(query: SERPQuery, url: string = ''): Promise<JsonReply<SERPQuery>> {
        return this.httpClient.post<JsonReply<SERPQuery>>(url, query).toPromise();
    }

    @Route('Query/Delete')
    public deleteQuery(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }

    @Route('Query/Schedule')
    public addScheduledQuery(id: number, url: string = ''): Promise<JsonReply<SERPQuery>> {
        return this.httpClient.post<JsonReply<SERPQuery>>(url, id).toPromise();
    }

    @Route('Result/ForQuery')
    public getResultsForQuery(param: BaseParams, url: string = ''): Promise<JsonReplyWithParams<SERPResult[], BaseParams>> {
        return this.httpClient.post<JsonReplyWithParams<SERPResult[], BaseParams>>(url, param).toPromise();
    }

    @Route('Link/ForResult')
    public getLinksForResult(linkId: any, url: string = ''): Promise<JsonReply<SERPLinksAndPreview>> {
        return this.httpClient.post<JsonReply<SERPLinksAndPreview>>(url, linkId).toPromise();
    }

    @Route('Link/PositionTrend')
    public getLinkPositionTrend(param: GetSERPLinkTrendParams, url: string = ''): Promise<JsonReply<SERPLinkPositionTrend>> {
        return this.httpClient.post<JsonReply<SERPLinkPositionTrend>>(url, param).toPromise();
    }

    @Route('Link/DiagramForResult')
    public getDiagram(params: BaseParamsWithContactId, url: string = ''): Promise<JsonReply<SERPLinkPositionTrend>> {
        return this.httpClient.post<JsonReply<SERPLinkPositionTrend>>(url, params).toPromise();
    }

    @Route('Query/CustomerHasQuery')
    public customerHasQuery(customerId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerId).toPromise();
    }

    @Route('Query/UrlByParam')
    public getQueryByParam(params: SerpQueryParams, url: string = ''): Promise<JsonReply<SERPLinkPositionTrend>> {
        return this.httpClient.post<JsonReply<SERPLinkPositionTrend>>(url, params).toPromise();
    }

    //se -> 1 per google, se saranno da implementare altri casi guardare enumerator SearchEngines
    @Route('Query/ParametersItems')
    public getParametersItems(se: 1, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, se).toPromise();
    }

    @Route('Query/SentimentTrend')
    public getSentimentTrend(param: SerpQueryTrendParam, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param).toPromise();
    }

}