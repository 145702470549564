import {CDashboard} from "./CDashboard";
import {Statistics} from "./Statistics";

export let dashboardMock: CDashboard = new CDashboard();
dashboardMock.contactId = 34;
dashboardMock.customerName = 'Walter';
dashboardMock.customerSurname = 'White';
dashboardMock.contactName = 'Jessy';
dashboardMock.contactSurname = 'Pinkman';
dashboardMock.contactMobilePhone = '1233456678';
dashboardMock.contactEmailAddress = 'walter@white.com';
dashboardMock.companyName = 'KFC';
dashboardMock.contactStatus = 3;
dashboardMock.firstContactDate = new Date();
dashboardMock.lastRecallDate = new Date();
dashboardMock.caseType = 3;
dashboardMock.notes = 'lorem lorem lorem lorem lorem lorem';

dashboardMock.assignedAccount = null;
dashboardMock.assignedSales = null;

dashboardMock.requiredServices = [];

dashboardMock.statistics = new Statistics();

