export class SERPpreview {
    PositiveLinksCount: number = 0;
    NegativeLinksCount: number = 0;
    LowNegativeLinksCount: number = 0;
    UnrelatedLinksCount: number = 0;
    NeutralLinksCount: number = 0;
    ChannelsControlled: number = 0;
    VideoCount: number = 0;
    ImageCount: number = 0;
    NewsCount: number = 0;
    SublinkCount: number = 0;
    TotalLinksCategorized: number = 0;
    PercentCategorized: number = 0;
    PercentPositive: number = 0;
    PercentUnrelated: number = 0;
    PercentNegative: number = 0;
    PercentLowNegative: number = 0;
    PercentNeutral: number = 0;
    PercentChannelsControlled: number = 0;
    PercentVideo: number = 0;
    PercentImage: number = 0;
    PercentNews: number = 0;
    PercentSubLink: number = 0;
}