import {Component, Input, OnInit} from '@angular/core';
import {Enums, SerpLastExecution} from '../../instruments/models';
import {DatePipe, JsonPipe, NgForOf, NgIf, TitleCasePipe} from '@angular/common';
import {NumberWithSignPipe} from '../../instruments/helpers';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-serp-position',
  standalone: true,
  imports: [
    JsonPipe,
    NgIf,
    NgForOf,
    NumberWithSignPipe,
    TitleCasePipe,
    DatePipe,
    TranslateModule
  ],
  templateUrl: './serp-position.component.html',
  styleUrl: './serp-position.component.css'
})
export class SerpPositionComponent implements OnInit{
  @Input({required: true}) data: SerpLastExecution[] = [];
  protected readonly enums = new Enums();

  ngOnInit() {
    console.log(this.data);
  }
}
