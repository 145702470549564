import { CustomerServiceModel } from "./CustomerServiceModel";
import { DomainUser} from "../DomainUser";
import { Guid } from '../Guid';
import { CustomerReferent } from "../customer/CustomerReferent";
import { Project } from "../project/Project";


export class Contact {
    _position: number;
    id: number;
    customerId: number;
    recallElapsedStatus: number;
    recallDate: Date;
    recallType: number;
    contentId: number = 0;

    // WORKFLOW
    //preAnalysisStatus serve any perche' viene formattato a stringa
    preAnalysisStatus: any;
    workflow: { [status: number]: Date };
    workflowResult: number;

    // CONTACT DETAILS
    firstContactDate: Date;
    firstContactUser: DomainUser;
    assignedSales: DomainUser;
    // assignedAccount: DomainUser;
    assignedAccountId: string;
    salesRelationship: number;
    commercialNotes: string;
    message: string;

    // CONTACT DETAILS: Main contact
    name: string;
    surname: string;
    emailAddress: string;
    mobilePhone: string;
    officePhone: string;
    housePhone: string;
    actualJob: string; // same in FACT CHECKING section
    companyName: string;
    closingNotes: string;
    isUnderAge: boolean;
    languages: string[];
    topLevelDomains: string[];
    searchEngines: number[];

    // REQUEST INFORMATION
    caseType: number[];
    serpHtml: string; // HTML SERP Google Page

    //FACT CHECKING
    feasibilityExpectations: string;
    feasibilityBrief: string;
    feasibility: string;
    storyNotes: string; // Feasibility notes
    feasibilityIntervention: boolean;
    feasibilityEvalutationResult: number;
    feasibilityAppealToGuarantor: boolean;
    feasibilityLegalActionPossibility: boolean; // Possibility of legal action
    feasibilityFromWebRequest: boolean;

    // REQUESTED SERVICES
    requiredServices: CustomerServiceModel[];

    documentsCount: number;

    feasibilityMappingCompleted: boolean;
    isReferentCheck: boolean;
    referents: CustomerReferent[];

    salesContractDate: Date;
    salesContractEndDate: Date;
    workStartDate: Date;

    project: Project;

    constructor(values: Object) {
        let now = new Date();

        this.id = 0;
        this.customerId = 0;
        this.recallElapsedStatus = 0;
        this.recallDate = now;
        this.recallType = 0;

        // WORKFLOW
        this.preAnalysisStatus = 1;
        this.workflow = null;
        this.workflowResult = 0;

        // CONTACT DETAILS
        this.firstContactDate = now;
        this.firstContactUser = DomainUser.Default;
        this.assignedSales = DomainUser.Default;
        this.assignedAccountId = Guid.Empty;
        this.salesRelationship = 0;
        this.commercialNotes = "N/A";

        // CONTACT DETAILS: Main contact
        this.name = null;
        this.surname = null;
        this.emailAddress = null;
        this.mobilePhone = null;
        this.officePhone = null;
        this.housePhone = null;
        this.actualJob = null;
        this.companyName = null;
        this.closingNotes = null;
        this.isUnderAge = false;
        this.message = null;
        this.languages = [];
        this.topLevelDomains = [];
        this.searchEngines = [];

        // REQUEST INFORMATION
        this.caseType = [0];

        // FACT CHECKING
        this.feasibilityExpectations = null;
        this.feasibilityBrief = null;
        this.feasibility = null;
        this.storyNotes = null;
        this.feasibilityIntervention = false;
        this.feasibilityEvalutationResult = 0;
        this.feasibilityAppealToGuarantor = false;
        this.feasibilityLegalActionPossibility = false;
        this.feasibilityFromWebRequest = false;

        // REQUESTED SERVICES
        this.requiredServices = [];

        this.documentsCount = 0;

        this.project = {} as Project;

        //Constructor initialization
        if (values) {
            Object.assign(this, values);
        }

        this.feasibilityMappingCompleted = false;
        this.referents = [];

        this.salesContractDate = null;
        this.salesContractEndDate = null;
        this.workStartDate = null;
    }
}

