import { LinkEntitiesParams } from '../api/LinkEntitiesParams';
import { Config } from '../../../app.constants';
import { TableAction } from './TableAction';
import { RichEnum } from '../RichEnum';
import { Enums, UpdateType } from '../Enums';
import * as moment from 'moment';

export interface TableFormat {
    format?: (...args: any[]) => string | RichEnum;
    label: string;
    sort: boolean;
    rScore?: boolean;
    type?: string;
    list?: boolean;
    plainText?: boolean;
    longTextWithFormat?: boolean;
    actionConfig?: {tooltip: string, icon: string};
    translate?: boolean;
    enum?: (...args: any[]) => RichEnum;
    colorEnum?: any;
}

export interface FilterCustomSelectOption {
    name: string;
    value: any;
}

export class FilterSelectEnumOptions {
    public static readonly Unknown: FilterCustomSelectOption = {
        name: 'ENUMS.UNKNOWN',
        value: 0
    };

    public static readonly Any: FilterCustomSelectOption = {
        name: 'ENUMS.ANY',
        value: RichEnum.Any
    };
}

export interface Filter {
    name: string;
    formName: string;
    type: string;
    class: string;
    param: string;
    enum?: string;
    choices?: any;
    macroCategories?: any;
    additionalOptions?: FilterCustomSelectOption[]
}

export interface Checkbox {
    customEvent: boolean;
    actionUrl?: string;
}

export interface UpdateRow {
    action?: Function;
    enabled: boolean;
    type?: UpdateType;
}

export interface Paths {
    basePath: string;
    deleteManyPath: string;
    updatePath: (...args: any[]) => string;
    fetchPath: string;
    exportManyPath: string;
    exportAllPath: string;
    linkToEntityPath: string;
}

export class TableOptions {
    buttons: TableAction[] = [];
    enums: Enums = new Enums();
    config: any = Config;
    paths: Paths = {
        basePath: '',
        deleteManyPath: '',
        updatePath: () => '',
        fetchPath: '',
        exportManyPath: '',
        exportAllPath: '',
        linkToEntityPath: ''
    };
    displayedColumns: string[];
    params: any;
    newParams: any;
    linkingParams: LinkEntitiesParams = new LinkEntitiesParams();
    formats: {[key: string]: TableFormat};
    update: UpdateRow = {
        action: null,
        enabled: false,
        type: null,
    };
    showFilters: boolean = false;
    filters: Filter[];
    tab?: any;
    exportName: string;
    checkbox: Checkbox;
    noContactId: boolean = false;
    pagination: { enabled: boolean, showButtons: boolean } = {
        enabled: true,
        showButtons: true,
    };

    constructor(path: string = null, isCustomer: boolean = false) {
        this.checkbox = {customEvent: false};
        this.paths.basePath = path;

        this.setPaths(path, isCustomer);
    }

    setPaths(path: string, isCustomer: boolean): void {
        if (path !== null) {
            this.paths.deleteManyPath = this.config.ApiUrls.deleteMany(path);
            this.paths.updatePath = () => this.config.ApiUrls.update(path);
            this.paths.fetchPath = isCustomer ? this.config.ApiUrls.forCustomer(path) : this.config.ApiUrls.forContact(path);
            this.paths.exportManyPath = this.config.ApiUrls.exportMany(path);
            this.paths.exportAllPath = this.config.ApiUrls.exportAll(path);
        }
    }

    formatter(columnName: string, value: any, entity: any): string | string[] {
        const column = this.formats[columnName];
        if (((value !== false && !value) || (value.length && value.length === 0)) && value !== 0 && columnName !== 'selectItems') {
            return '-';
        } else {
            if (columnName === 'isLinked') {
                return value;
            }

            if (column && (column.format || column.type)) {
                switch (column.type) {
                    case 'enumMulti':
                    case 'enum': {
                        let item = (column.format(entity) as RichEnum).getForValue(value);
                        if (item instanceof Array) {
                            return item.map(i => i.toString());
                        }

                        return item.toString();
                    }
                    case 'date':
                        if (entity.dayUnknown) {
                            return moment(value).format(Config.MomentShortDateFormat);
                        } else if (column.format && column.format() === 'fromNow') {
                            return moment(value).fromNow();
                        } else {
                            return moment(value).format(Config.MomentDateFormat);
                        }
                    case 'dateTime':
                        return moment(value).format(Config.MomentDateTimeFormat);
                    case 'domainUser':
                        return value.fullName;
                    default:
                        return value;
                }
            }
            return value;
        }
    }

    resetParams(): any {
        return this.newParams;
    }

}

