import { DomainUser } from "../DomainUser";
import { Guid } from "../Guid";
import { MatrixValues } from "../valueMatrix/CustomerValueMatrix";

export class Project {
    id: number = 0;
    clientId: number = 0;
    name: string = null;
    surname: string = null;
    companyName: string = null;
    assignedSales: DomainUser = null;
    assignedAccount: DomainUser = null;
    assignedAccountId: string = Guid.Empty;
    valueMatrix: MatrixValues = null;
    hasValueMatrix: boolean = false;
    criticalAspects: string = null;
    goalKeyConcepts: string = null;
    projectBrief: string = null;
    languages: string[];
    topLevelDomains: string[];
    searchEngines: number[];
}
