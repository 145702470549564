<!-- CLOSE BTN: Start -->
<div class="position-relative">
    <button mat-icon-button class="close-dialog-btn" type="button" #closeBtn (click)="_cancel()">
        <img src="/app_assets/svg/material-icons/clear_grey.svg">
    </button>
</div>
<!-- CLOSE BTN: End -->
<!-- HEADING: Start -->
<h2 mat-dialog-title class="justify-content-start p-0 mb-3 heading-underline-blue">
    <img class="mr-3 dialog-icon"
         *ngIf="data.icon"
         src="/app_assets/svg/material-icons/{{data.icon}}.svg">
    <strong>{{data.title  | translate:data.translateParams}}<span *ngIf="data.afterTitle"> - {{data.afterTitle}}</span></strong>
</h2>
<!-- HEADING: End -->
<!-- ACTIONS: Start -->
<mat-dialog-actions *ngIf="data.type === 'default'">
    <button mat-button color="primary" (click)="_ok()">Ok</button>
    <button *ngIf="data.showCancelBtn" mat-button color="primary" (click)="_cancel()">{{'BUTTONS.CANCEL'| translate }}</button>
</mat-dialog-actions>
<!-- ACTIONS: End -->
<!-- LINK TREND TEMPLATE -->
<div *ngIf="data.type === 'serpLinkTrend'">
    <app-link-position-trend style="font-family: Roboto,'Helvetica Neue',sans-serif;position:relative;display:block" [trend]="data.trend"></app-link-position-trend>
</div>

<!-- LINK TREND TEMPLATE -->
<div *ngIf="data.type === 'serpGenerator'">
    <app-serp-generator></app-serp-generator>
</div>

<!-- SERP POSITION -->
<div *ngIf="data.type === 'serpPosition'">
    <app-serp-position [data]="data.data"></app-serp-position>
</div>
