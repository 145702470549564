// Core
import { NgModule, ErrorHandler } from '@angular/core';

import {
    CustomerService,
    ContactsLinkedServicesService,
    DomainUserService,
    ProjectService,
    ContentService,
    SharedService,
    CustomerLinksService,
    AlertService,
    ChannelService,
    HttpInterceptorService,
    ErrorsHandler,
    DomainService,
    ValueMatrixService,
    HttpCancelService,
    CustomerProjectService,
    SERPService
} from './';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@NgModule({
    providers: [
        CustomerService,
        ContactsLinkedServicesService,
        DomainUserService,
        AlertService,
        ProjectService,
        ContentService,
        SharedService,
        CustomerLinksService,
        ChannelService,
        HttpInterceptorService,
        ValueMatrixService,
        DomainService,
        HttpCancelService,
        CustomerProjectService,
        SERPService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler,
            multi: false
        }
    ],
})
export class ServicesModule { }
