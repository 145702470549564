import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';

import { GetContentParams, JsonReply, ContentModel, JsonReplyWithParams, LinksToAssets, BRCustomerContentId, GetContentLightParams, BaseParamsWithContactId } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { SharedService } from './shared.service';

@Injectable()
@RoutePrefix('/api/Content')
export class ContentService {
    constructor(private httpClient: HttpClient,
        private shared: SharedService) { }

    @Route('ForCustomer')
    public getContentForCustomer(params: GetContentParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('Find')
    public findContents(params: GetContentLightParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('Get')
    public getContents(params: GetContentParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('MyUniumXGet')
    public myUniumXGetContents(params: GetContentParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('Add')
    public addContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('MyUniumXAdd')
    public addMyUniumxContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('Upload')
    public upload(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('MyUniumXUpload')
    public myuniumxupload(file: any, contactId: number, notes: string, url: string = ''): Promise<HttpEvent<any>> {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('ContactId', contactId.toString());
        headers = notes ? headers.append('CommonNotes', notes.toString()) : headers.append('CommonNotes', '');

        return this.httpClient.post<any>(url, file, { 'headers': headers, 'observe': 'events', 'reportProgress': true, responseType: 'json' }).toPromise();
    }

    @Route('Update')
    public updateContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('MassiveUpdate')
    public updateMassiveContent(param: any, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param)
            .toPromise();
    }

    @Route('NotUsed')
    public setAsNotUsed(contentId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contentId).toPromise();
    }

    @Route('ById')
    public getById(BRCustomerContentId: BRCustomerContentId, url: string = ''): Promise<JsonReply<ContentModel>>{
        return this.httpClient.post<JsonReply<ContentModel>>(url, BRCustomerContentId).toPromise();
    }

    @Route('Delete')
    public delete(param: BRCustomerContentId, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param).toPromise();
    }

    @Route('Download')
    public download(id: BRCustomerContentId, url: string = ''): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');

        return this.httpClient.post(url, id, { headers: headers, responseType: 'blob' }).toPromise();
    }

    @Route('MaxUploadFileSize')
    public maxUploadFileSize(url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.get<JsonReply<any>>(url).toPromise();
    }

    //post refactor

    @Route('DeleteFile')
    public deleteFile(assetId: number, url: string = ''): Promise<JsonReply<ContentModel>> {
        return this.httpClient.post<JsonReply<ContentModel>>(url, assetId).toPromise();
    }
}
