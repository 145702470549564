<div class="row serpGenerator">
    <div class="col-sm-12">
        <div class="row">
            <!-- citta o nazione -->
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-12 d-flex">
                        <button mat-raised-button style="width:120px; height:40px;"
                                color="{{showIT ? 'accent' : 'primary'}}"
                                class="mr-2 mr-xl-3 mr-sm-1 chipsFilter"
                                id="blackWords"
                                (click)="changeCity('it')">
                            Italia
                        </button>

                        <button mat-raised-button style="width:120px; height:40px;"
                                color="{{ !showIT ? 'accent' : 'primary'}}"
                                class="mr-2 mr-xl-3 mr-sm-1 chipsFilter"
                                id="blackWords"
                                (click)="changeCity('all')">
                            Mondo
                        </button>
                    </div>
                </div>
                <!-- IT -->
                <mat-form-field class="w-100 mt-4" *ngIf="showIT">
                    <mat-label>Città</mat-label>
                    <input type="text"
                           class="underline"
                           name="cityAC"
                           id="select-pad2"
                           matInput
                           required
                           [formControl]="myControlCityIT"
                           [matAutocomplete]="autoCity">
                    <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="getCity($event.option.value)" [displayWith]="displayOption">
                        <mat-option *ngFor="let option of filteredCitiesIT | async" [value]="option">
                            {{option.text}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>Inserire una città valida</mat-error>
                </mat-form-field>
                <!-- All -->
                <mat-form-field class="w-100 mt-4" *ngIf="!showIT">
                    <mat-label>Città</mat-label>
                    <input type="text"
                           class="underline"
                           name="cityAC"
                           id="select-pad2"
                           matInput
                           required
                           [formControl]="myControlCityAll"
                           [matAutocomplete]="autoCity">
                    <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="getCity($event.option.value)" [displayWith]="displayOption">
                        <mat-option *ngFor="let option of filteredCitiesAll | async" [value]="option">
                            {{option.text}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>Inserire una città valida</mat-error>
                </mat-form-field>
            </div>
            <!-- browser -->
            <div class="col-sm-3">
                <mat-form-field id="select-pad2" class="rm-paginator-select p-0 w-100">
                    <mat-select [formControl]="myControlBrowser" [(ngModel)]="BrowserQuery" placeholder="Browser" required>
                        <mat-option *ngFor="let option of browserList" [value]="option">{{option.text}}</mat-option>
                    </mat-select>
                    <div class="underline"></div>
                    <mat-error>Inserire un valore</mat-error>
                </mat-form-field>
            </div>
            <!-- City -->
            <div class="col-sm-3">
                <mat-form-field  id="select-pad2" class="rm-paginator-select p-0 w-100">
                    <mat-select  [formControl]="myControlGl" [(ngModel)]="GLQuery" placeholder="Nazione" required>
                        <mat-option *ngFor="let option of glList" [value]="option">{{option.text}}</mat-option>
                    </mat-select>
                    <div class="underline"></div>
                    <mat-error>Inserire un valore</mat-error>
                </mat-form-field>
            </div>
            <!-- dominio -->
            <div class="col-sm-6">
                <mat-form-field class="rm-paginator-select pt-1 p-0 w-100">
                    <mat-label >Motore</mat-label>
                    <input type="text"
                           id="select-pad2"
                           class="underline"
                           name="domain"
                           matInput
                           required
                           [formControl]="myControlDomain"
                           [matAutocomplete]="autoDomain">
                    <mat-autocomplete #autoDomain="matAutocomplete" (optionSelected)="getDomain($event.option.value)" [displayWith]="displayOptionValue">
                        <mat-option *ngFor="let option of filteredDomain | async" [value]="option">{{option.value}}</mat-option>
                    </mat-autocomplete>
                    <mat-error>Inserire una dominio</mat-error>
                </mat-form-field>
            </div>
            <!-- linguaggio -->
            <!--<div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Linguaggio</mat-label>
                    <input type="text"
                           name="langAC"
                           placeholder="Scrivi e seleziona dal menu a tendina"
                           aria-label="Lang"
                           matInput
                           [formControl]="myControlLang"
                           [matAutocomplete]="autoLang">
                    <mat-autocomplete #autoLang="matAutocomplete" (optionSelected)="getLang($event.option.value)" [displayWith]="displayOption">
                        <mat-option *ngFor="let option of filteredLang | async" [value]="option">
                            {{option.text}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>-->
            <!-- keyword -->
            <div class="col-sm-12">
                <form>
                    <mat-form-field class="w-100">
                        <mat-label>Keyword</mat-label>
                        <input type="text"
                               class="underline"
                               required
                               id="select-pad2"
                               name="keyword"
                               [(ngModel)]="keyword"
                               [formControl]="myControlKeyword"
                               matInput>
                        <mat-error>Inserire una o più parole</mat-error>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-sm-3">
                <button mat-raised-button
                        color="accent"
                        (click)="getQuery()"
                        id="blackWords"
                        class="uniumx-button w-100 mt-4 text-bold">
                    Crea query
                </button>
            </div>
            <div class="col-sm-3">
                <button mat-raised-button
                        color="primary"
                        (click)="reset()"
                        id="blackWords"
                        class="uniumx-button w-100 mt-4 text-bold">
                    Reset
                </button>
            </div>
        </div>
    </div>



    <div class="col-12 d-flex align-items-center mt-4" *ngIf="serpQuery">
        <img class="mr-2 cursor-pointer" src="/app_assets/svg/material-icons/copy.svg" (click)="copyToBoard(serpQuery)" />
        <a href="{{serpQuery}}" target="_blank" class="text-overflow">{{serpQuery}}</a>
    </div>

    <div class="col-12">
        <p class="error-text" *ngIf="showError">Compilare tutti i campi.</p>
        <p class="ok-text" *ngIf="copytext">Query copiata correttamente.</p>
    </div>
</div>