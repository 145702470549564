// Core
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

// Instruments
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply } from '../models/api/JsonReply';
import { GetContactLinkParams } from '../models/api/GetContactLinkParams';
import { SharedService } from './shared.service';
import { JsonReplyWithParams, ContentModel } from 'src/app/instruments/models';


@Injectable()
@RoutePrefix('/api/ContactLink')
export class CustomerLinksService {
    constructor(private httpClient: HttpClient,
                private shared: SharedService) { }

    @Route('Add')
    public addLink(event: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, event)
            .toPromise();
    }

    @Route('Delete')
    public deleteLink(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }

    @Route('DeleteAll')
    public deleteAllLinks(contactId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactId)
            .toPromise();
    }

    @Route('DeleteMany')
    public deleteLinks(idList: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, idList)
            .toPromise();
    }

    @Route('ExportMany')
    public exportLinks(idList: number[], url: string = ''): void {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        headers = headers.append('IdList', idList.join(','));

        this.httpClient.get(url, { headers: headers, responseType: 'blob' }).subscribe((resp: any) => {
            this.shared.downloadBlobFile(resp, 'contact_links.xlsx');
        });
    }
}
