import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
    apiURL = environment.apiURL;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('/api/') !== -1) {
            const apiReq = req.clone({
                withCredentials: true,
                url: this.apiURL + `${req.url}`
            });
            return next.handle(apiReq);
        }
        else {
            return next.handle(req);
        }
    }
}