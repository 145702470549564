// Core
import { Component, ElementRef, OnInit, Input, NgZone, OnDestroy, AfterViewInit } from '@angular/core';

// Instruments

import { SERPLinkPositionTrend } from '../../instruments/models';
// import { isNullOrUndefined } from 'util';

import { SharedService, stringToDate } from '../../instruments/services';

interface ITrendChartDatasetItem {
    date: number,
    value: number,
    bullet: boolean
}

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

@Component({
    selector: 'app-link-position-trend',
    styleUrls: ['./link-position-trend.component.css'],
    templateUrl: './link-position-trend.component.html',
    standalone: true,
})
export class LinkPositionTrendComponent implements OnInit, AfterViewInit, OnDestroy {
    
    private static _counter = 1;

    public divId: number;
    public trendValid: boolean = false;

    @Input() trend: SERPLinkPositionTrend;

    constructor(
        private zone: NgZone,
        public shared: SharedService
    ) {
    }

    ngOnInit(): void {
        if (this.trend) {
            this.trend.dates = this.trend.dates.map(d => stringToDate(d));
            this.trendValid = this.trendIsValid();
        }
    }

    ngAfterViewInit(): void {
        if (this.trend && this.trendIsValid()) {
            this.zone.runOutsideAngular(() => {
                this.initChart();
            });
        }
    }

    ngOnDestroy(): void {

    }

    private initChart(): void {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("chartdivSerp");


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX"
        }));

        chart.get("colors").set("step", 3);


        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.3,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            min: Date.now() - 29116800000,
            max: Date.now() + 2419200000,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        var max = 0;

        this.trend.positions.map(pos => {
            if (pos.position > max) {
                max = pos.position;
            }
        });

        max = max + 5;

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            min: 0,
            max: max,
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true
            })
        }));


        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            })
        }));
        series.strokes.template.setAll({
            strokeWidth: 2,
            strokeDasharray: [3, 3]
        });

        // Create animating bullet by adding two circles in a bullet container and
        // animating radius and opacity of one of them.
        series.bullets.push(function (root, series, dataItem) {
            if ((dataItem.dataContext as any).bullet) {
                var container = am5.Container.new(root, {});
                var circle0 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0x979fab)
                }));
                var circle1 = container.children.push(am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0x2f4057)
                }));

                circle0.animate({
                    key: "radius",
                    to: 20,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });
                circle1.animate({
                    key: "opacity",
                    to: 0.7,
                    from: 1,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                    loops: Infinity
                });

                return am5.Bullet.new(root, {
                    sprite: container
                })
            }
        })

        var data = this.trendToDatasetMapper(this.trend);

        data[data.length - 1].bullet = true;

        series.data.setAll(data);


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);
    }

    private trendToDatasetMapper(trend: SERPLinkPositionTrend): ITrendChartDatasetItem[] {
        return trend.positions.map(p => { return { value: p.position, date: new Date(p.date).getTime(), bullet: false } });
    }

    private trendIsValid(): boolean {
        return this.trend && this.trend.dates && this.trend.positions && this.trend.dates.length > 0 && this.trend.positions.length > 0;
    }
}