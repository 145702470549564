export interface IUploadedDocument {
    id: number;
    idCode: number;
    customerId: number;
    insertDate: Date;
    fileId: string;
    fileName: string;
}

export class CustomerDocument implements IUploadedDocument {
    id: number = 0;
    idCode: number = 0;
    customerId: number = 0;
    insertDate: Date = null;
    fileId: string = null;
    fileName: string;
}
