import { SERPSentiment } from "./Enums";
import { ContentModel } from "./content/ContentModel";

export interface SERPLinkPositionData {
    linkId: number;
    position: number;
    date: string;
}

export interface SERPLinkPositionTrend {
    positions: SERPLinkPositionData[];
    dates: Date[];
    link: string;
}

export class SERPQuery {

    id: number = 0;
    insertDate: Date = new Date();
    language: number = 0;
    keyword: string = null;
    frequency: number = 0;
    scheduledDate: Date = null;
    canSchedule: boolean = false;
    processed: boolean = false;

    results: SERPResult[] = [];

    constructor(public customerId: number) {

    }
}

export class SERPResult {
    _index: number;
    id: number;
    queryId: number;
    queryDate: Date;
    success: boolean;

    // UI only
    totalLinks: number = 0;
    totalLinksCategorized: number = 0;
    percentCategorized: number = 0;
    positiveLinksCount: number = 0;
    unrelatedLinksCount: number = 0;
    negativeLinksCount: number = 0;
    neutralLinksCount: number = 0;
    lowNegLinksCount: number = 0;

    percentPositive: number = 0;
    percentUnrelated: number = 0;
    percentNegative: number = 0;
    percentNeutral: number = 0;
    percentLowNeg: number = 0;

    prevTotalLinks: number = 0;
    prevTotalLinksCategorized: number = 0;
    prevPositiveLinksCount: number = 0;
    prevUnrelatedLinksCount: number = 0;
    prevNegativeLinksCount: number = 0;

    prevPercentPositive: number = 0;
    prevPercentUnrelated: number = 0;
    prevPercentNegative: number = 0;

    negativeDiff: number = 0;
    positiveDiff: number = 0;
    unrelatedDiff: number = 0;

    negativePercentDiff: number = 0;
    positivePercentDiff: number = 0;
    unrelatedPercentDiff: number = 0;

    links: ContentModel[];
}