import 'reflect-metadata';


/**
 * Decorator to add routePrefix property to any API service
 * @param constructor
 */
export function RoutePrefix(prefix: string) {
    var apiPrefix = prefix;
    return function (constructor: Function) {
        constructor.prototype.routePrefix = apiPrefix;
    }
}


export function Default(typeF: Function): any {
    
    if (typeF === Date) {
        return new Date();
    }

    if (typeF === Number) {
        return 0;
    }

    if (typeF === String) {
        return null;
    }

    if (typeF === Boolean) {
        return false;
    }

    return null;

}

export function Init(value?: any) {
    if (value !== undefined) {
        return function _initWithValue(target: any, propertyKey: string): void {
            let t = Reflect.getMetadata("design:type", target, propertyKey);
            
            let isValidType: boolean = false;
            if (t.name === 'String' || t.name === 'Number' || t.name === 'Boolean') {
                isValidType = t.name.toLowerCase() == typeof value;
            } else {
                isValidType = value instanceof t;
            }
            
            if (isValidType) {
                target[propertyKey] = value;
            } else {
                console.error('value ' + value + ' is not of type ' + t.name);
            }
            
            console.log('target: ', target);
        }
    }

    return function _init(target: any, propertyKey: string): void {
        let type = Reflect.getMetadata("design:type", target, propertyKey);
        let def = Default(type);
        console.log('target: ', target);
        console.log('propertyKey: ', propertyKey);
        console.log('type: ', type);
        console.log('def: ', def);

        target[propertyKey] = def;

        console.log('target: ', target);
    }
}
