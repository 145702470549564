import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { SharedService } from './instruments/services';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {

    constructor(
        private _router: Router,
        public translate: TranslateService,
        private shared: SharedService,
    ) {
    }

    ngOnInit() {
        this.configureTranslateService();
    }

    private configureTranslateService(): void {
        this.translate.addLangs(['it', 'en']);
        this.translate.setDefaultLang('it');

        this.translate.onLangChange.subscribe((ev: LangChangeEvent) => {
            moment.locale(ev.lang);
        });

        const currLang = 'it';

        this.translate.use(currLang);
        moment.locale(currLang);
        localStorage.setItem('LOCALE', currLang);
        this.shared.currentLang = currLang;
    }
}
