<div class="row overflow-mobile">
    <div class="col-12">
        <div class="row m-0 mb-4">
            <div class="col-2 col-md-1 fw-bold">Posizione</div>
            <div class="col-2 col-md-1 fw-bold">Variazione</div>
            <div class="col fw-bold">Keyword</div>
            <div class="col fw-bold">Motore</div>
            <div class="col fw-bold">Frequenza</div>
            <div class="col fw-bold">Data ultima esecuzione</div>
        </div>
        @for (d of data; track $index){
            <div class="row m-0 mt-2">
                <div class="col-2 col-md-1">{{d.position}}</div>
                <div class="col-2 col-md-1">{{d.variation}}</div>
                <div class="col">{{d.querySearchKeywords}}</div>
                <div class="col">{{d.querySearchEngineHost}}</div>
                <div class="col">{{enums.SERPFrequency.getForValue(d.frequency).toString() | translate}}</div>
                <div class="col">{{d.lastExecutionDate | date:'dd/MM/YYYY HH:mm'}}</div>
            </div>
        } @empty {
            <div class="row m-0 mt-2 justify-content-lg-center">
                <strong>Link non presente nelle ultime acquisizioni</strong>
            </div>
        }
    </div>
</div>
