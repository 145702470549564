import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, JsonReplyWithParams, GetDomainParams, Domain, DomainCategory, CustomerChannel, ContentModel, BRCustomerContentId, ChannelsForStatuses } from "../models";

@Injectable()
@RoutePrefix('/api/Domain')
export class DomainService {
    constructor(private httpClient: HttpClient) { }

    @Route('Find')
    public find(param: GetDomainParams, url: string = ''): Promise<JsonReply<Domain[]>> {
        return this.httpClient.post<JsonReply<Domain[]>>(url, param).toPromise();
    }

    @Route('Linked')
    public getForTTD(param: GetDomainParams, url: string = ''): Promise<JsonReply<Domain[]>> {
        return this.httpClient.post<JsonReply<Domain[]>>(url, param).toPromise();
    }

    @Route('ById')
    public getById(id: number, url: string = ''): Promise<JsonReply<Domain>> {
        return this.httpClient.post<JsonReply<Domain>>(url, id).toPromise();
    }

    @Route('Add')
    public add(domain: Domain, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, domain).toPromise();
    }

    @Route('Update')
    public update(domain: Domain, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, domain).toPromise();
    }

    @Route('UpdateCustomerChannelWithContent')
    public updateCustomerChannelContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('Delete')
    public delete(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }

    @Route('DeleteMany')
    public deleteMany(ids: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, ids).toPromise();
    }

    @Route('GetCategorys')
    public getDomainCategorys(url: string = ''): Promise<JsonReply<DomainCategory[]>> {
        return this.httpClient.get<JsonReply<DomainCategory[]>>(url).toPromise();
    }

    @Route('CustomerChannelForCustomer')
    public getForCustomer(params: ChannelsForStatuses, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], ChannelsForStatuses>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], ChannelsForStatuses>>(url, params).toPromise();
    }

    @Route('UpdateCustomerChannel')
    public updateCustomerChannel(customerChannel: CustomerChannel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerChannel).toPromise();
    }

    @Route('AddCustomerChannel')
    public addCustomerChannel(customerChannel: CustomerChannel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerChannel).toPromise();
    }

    @Route('AddCustomerChannelWithContent')
    public addCustomerChannelWithContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content).toPromise();
    }

    @Route('DeleteCustomerChannel')
    public deleteCustomerChannel(id: BRCustomerContentId, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }
}